<template>
  <main class="mt-5">
    <div class="container-logo">
      <!-- <div class="container-logo-flex">
        <img :src="'/images/logo2.png'" class="company-logo"/>
      </div> -->
      <div class="container-description">
        <p class="text-uppercase-uni">
          REGISTRO UNIVERSITARIO
        </p>
      </div>
    </div>
    <FormStudentPartials></FormStudentPartials>
  </main>
</template>

<script>
import FormStudentPartials from "@/components/partials/FormStudent.partials.vue";

export default {
  components: {
    FormStudentPartials,
  },
};
</script>

<style land="scss">
@import "../styles/comunityuniversitaria.scss";
</style>
